import Layout from 'layout/Layout'
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AuthPage, VerificationPage } from 'ufinet-web-components'
import { AuthContext, authService, AuthStatus } from 'ufinet-web-functions'
import { PrivateRoutes } from './protected/PrivateRoutes'
import { Translation } from 'utils/translation/Translation'

export const PATH_AUTH = 'auth'

const AppRoutes: FC = () => {
	const { status, setStatus, token, setToken, logout, clear } = useContext(AuthContext)
	const prevToken = token

	const [isSSOLoggingIn, setIsSSOLoggingIn] = useState<boolean>()

	// get url after domain name and ? if exists
	const url = window.location.pathname + window.location.search
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const memoizedUrl = useMemo(() => url, [])

	useEffect(() => {
		authService.setUpBroadcastChannel({ onLogoutReceived: clear })

		setStatus(AuthStatus.VERIFYING)
		beginAuthentication()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (status === AuthStatus.LOGGED_OUT) return

		isSSOLoggingIn === false && checkAuthentication()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSSOLoggingIn, token])

	const beginAuthentication = () => {
		setIsSSOLoggingIn(true)
		authService
			.initSSOLogin(setToken)
			.catch(() => {
				console.warn('SSO login attempt failed')
				status !== AuthStatus.LOGGED_IN && toast.error(Translation('AUTH.LOGIN.DOWN'))
			})
			.finally(() => {
				setIsSSOLoggingIn(false)
			})
	}

	const checkAuthentication = useCallback(() => {
		if (!token && !prevToken) {
			logout()
			return
		}

		authService
			.isAuthenticated(setToken, token)
			.then((userData) => {
				setStatus(AuthStatus.LOGGED_IN, userData)
			})
			.catch(() => {
				toast.dismiss()
				toast.error(Translation('AUTH.SESSION.EXPIRED'))
				logout()
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token])

	// Periodically refresh token if user stays in page
	const refreshToken = useCallback(() => {
		if (status === AuthStatus.LOGGED_IN) authService.refreshToken(setToken)
	}, [status, setToken])

	useEffect(() => {
		const refreshInterval = setInterval(refreshToken, 15 * 60 * 1000)

		return () => {
			clearInterval(refreshInterval)
		}
	}, [refreshToken])

	// Auth components in ufinet-web-components zooms page on authentication
	// To avoind removing zoom from ufinet-web-components, we reset zoom here, although it's not ideal
	// if mobile, zoom is 80% by default
	useEffect(() => {
		const isMobile = window.innerWidth < 768
		// @ts-ignore
		document.body.style.zoom = isMobile ? '60%' : '100%'
		window.scrollTo(0, 0)
	}, [status])

	return (
		<BrowserRouter>
			<Routes>
				<Route element={<Layout />}>
					{status === AuthStatus.LOGGED_IN ? (
						<>
							<Route path="/*" element={<PrivateRoutes memoizedUrl={memoizedUrl} />} />
						</>
					) : status === AuthStatus.VERIFYING ? (
						<>
							<Route path="/*" element={<VerificationPage />} />
						</>
					) : (
						<>
							<Route path={`${PATH_AUTH}/*`} element={<AuthPage />} />
							<Route path="*" element={<Navigate to={PATH_AUTH} />} />
						</>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export { AppRoutes }
