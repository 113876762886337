import { FC, useState, useEffect, useContext } from 'react'
import { Table, UfinetBox, UfinetSectionBox, formatTableFilters, UfinetModal } from 'ufinet-web-components'
import { useModal } from 'ufinet-web-functions'
import { DataTablePFSEvent } from 'primereact/datatable'
import { AuthContext, Authority } from 'ufinet-web-functions'
import { useQuery } from '@tanstack/react-query'
import EditModal from 'components/modals/EditModal'
import { getRows } from 'api/requests'
import { Translation } from 'utils/translation/Translation'
const NewUfinetBox = UfinetBox as any

const ListCable: FC = () => {
	const authData = useContext(AuthContext)
	const roles = authData.userData?.authorities || []
	const permissions: any = Authority.getCablePermissions(roles)

	const cols = [
		{
			field: 'id',
			header: Translation('EDITMODAL.ID'),
			width: '190px',
			nonFilterable: false,
			type: 'text',
			filterType: 'text',
		},
		{
			field: 'name',
			header: Translation('EDITMODAL.NAME'),
			width: '390px',
			nonFilterable: false,
			type: 'text',
			filterType: 'text',
		},
		{
			field: 'country',
			header: Translation('EDITMODAL.COUNTRY'),
			width: '290px',
			nonFilterable: true,
			type: 'text',
			filterType: 'text',
		},
	]

	const initialFilterData = {
		pageNumber: 0,
		rowsPerPage: 20,
	}

	const [paging, setPaging] = useState<any>()
	const [filterData, setFilterData] = useState<{}>(initialFilterData)

	const [modalRow, setModalRow] = useState<any>({})
	const [editModal, showEditModal, hideEditModal] = useModal()

	const query = useQuery({
		queryKey: ['cables-list', filterData],
		queryFn: () => getRows(filterData),
	})
	const { data, isPending } = query

	useEffect(() => {
		if (data) {
			const parsePagination = JSON.parse(data?.headers['x-pagination'])
			const formattedPagination = {
				totalElements: parsePagination.totalCount,
				totalPages: parsePagination.totalPages,
				pageSize: parsePagination.rowsPerPage,
				pageNumber: parsePagination.currentPage,
			}
			setPaging(formattedPagination)
		}
	}, [data])

	useEffect(() => {
		// Clean component on unmount (changes pathname)
		return () => {
			setPaging(undefined)
			setFilterData(initialFilterData)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onFilterClear = (): void => {
		setFilterData(initialFilterData)
	}

	const onFilter = (e: DataTablePFSEvent): void => {
		const formattedFilters = formatTableFilters(e)
		setFilterData(formattedFilters)
	}

	const onPage = (e: DataTablePFSEvent): void => {
		setFilterData({ ...filterData, pageNumber: e.page, rowsPerPage: e.rows })
	}

	const onSort = (e: DataTablePFSEvent): void => {
		setFilterData({
			...filterData,
			sortField: e.sortField,
			sortOrder: e.sortOrder === 1 ? 'ASC' : e.sortOrder === -1 ? 'DESC' : null,
		})
	}

	function getEditPencilBodyTemplate<T>(rowData: T, onClick: () => void) {
		return (
			<div>
				<i className="pi pi-pencil edit-pencil" onClick={onClick} />
			</div>
		)
	}

	return (
		<>
			<NewUfinetBox>
				<UfinetSectionBox>
					<Table
						key="cables-list"
						dataKey="id"
						// @ts-ignore
						cols={cols}
						content={data?.data.map((item: FormData) => ({
							...item,
						}))}
						// headerButtons={getHeaderButtons()}
						actionBodyTemplate={
							permissions.canUpdate
								? (row: string) =>
										getEditPencilBodyTemplate(row, () => {
											setModalRow(row)
											showEditModal()
										})
								: undefined
						}
						emptyMessage={isPending ? Translation('LOADING_DOTS') : undefined}
						onFilterClear={onFilterClear}
						onSort={onSort}
						lazySettings={
							paging && {
								...paging,
								loading: isPending,
								onFilter,
								onPage,
								onSort,
							}
						}
					/>

					<UfinetModal
						size="lg"
						show={editModal}
						handleClose={() => {
							hideEditModal()
							setModalRow({})
						}}
						title={Translation('EDITMODAL.TITLE')}
					>
						<EditModal hideModal={hideEditModal} row={modalRow} successFn={() => setModalRow({})} />
					</UfinetModal>
				</UfinetSectionBox>
			</NewUfinetBox>
		</>
	)
}

export default ListCable
