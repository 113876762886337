/* eslint-disable react/jsx-no-target-blank */
import { FC, useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faEthernet } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
import { AsideMenuItem, AsideMenuItemWithSub } from 'ufinet-web-components'
import { AuthContext, Authority } from 'ufinet-web-functions'
import { Translation } from 'utils/translation/Translation'

enum AsideMenu {
	CABLES,
	NONE,
}

const AsideMenuMain: FC = () => {
	const location = useLocation()

	const [active, setActive] = useState(AsideMenu.NONE)
	const isCablesActive = () => active === AsideMenu.CABLES
	const activeMenu = (menu: AsideMenu) => () => setActive(menu)

	const { userData } = useContext(AuthContext)

	const roles = userData?.authorities || []
	const cablePermission = Authority.getCablePermissions(roles)

	return (
		<>
			{cablePermission.canRead && (
				<AsideMenuItemWithSub
					icon={<FontAwesomeIcon icon={faEthernet} className="fs-2x" />}
					title={Translation('MENU.CABLES.TITLE')}
					active={isCablesActive()}
				>
					<AsideMenuItem
						routerLocation={location}
						key="cables-read"
						to="cables/read"
						icon={faCircle}
						title={Translation('MENU.CABLES.READ')}
						onClick={activeMenu(AsideMenu.CABLES)}
					/>
					<AsideMenuItem
						routerLocation={location}
						key="cables-list"
						to="cables/list"
						icon={faCircle}
						title={Translation('MENU.CABLES.LIST')}
						onClick={activeMenu(AsideMenu.CABLES)}
					/>
				</AsideMenuItemWithSub>
			)}
		</>
	)
}

export { AsideMenuMain }
