import { AsideDefault } from 'layout/aside/AsideDefault'
import { AsideMobile } from 'layout/aside/AsideMobile'
import { FC, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { MasterLayout, WelcomePage, AuthRoute } from 'ufinet-web-components'
import { Translation } from 'utils/translation/Translation'
import { Authority } from 'ufinet-web-functions'

// CABLE PAGES
import ReadCable from 'pages/cables/ReadCable'
import ListCable from 'pages/cables/ListCable'

export const PATH_HOME = ''

// CABLES PATHS AND MODEL
export const PATH_CABLES = 'cables'
export const PATH_CABLES_READ = 'read'
export const PATH_CABLES_LIST = 'list'
export const PATH_CABLES_READ_MODEL = `${PATH_CABLES}/${PATH_CABLES_READ}`
export const PATH_CABLES_LIST_MODEL = `${PATH_CABLES}/${PATH_CABLES_LIST}`

//routerMap: traduccion de cada elemento del la url que se quiere mostrar en el menu de forma individual
//si tenemos un PATH_X="ejemplo/ejemplo2", en nuestro routerMap metemos una clave para ejemplo y otra para ejemplo2
export const routerNameMap = new Map<string, string>([
	[PATH_HOME, 'PATH_HOME'],
	[PATH_CABLES, 'MENU.CABLES.TITLE'],
	[PATH_CABLES_READ, 'MENU.CABLES.READ'],
	[PATH_CABLES_LIST, 'MENU.CABLES.LIST'],
])

export const routerLinkMap = new Map<string, string>([
	[PATH_HOME, `/${PATH_HOME}`],
	[PATH_CABLES, `/${PATH_CABLES}`],
	[PATH_CABLES_READ, `/${PATH_CABLES_READ}`],
	[PATH_CABLES_LIST, `/${PATH_CABLES_LIST}`],
])

export const routePermissionMap = new Map<string, Authority[]>([[PATH_CABLES, [Authority.cableModuleRead]]])

const PrivateRoutes: FC<{ memoizedUrl?: string }> = ({ memoizedUrl }) => {
	const location = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		if (memoizedUrl) {
			navigate(memoizedUrl)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memoizedUrl])

	return (
		<Routes>
			<Route
				element={
					<MasterLayout
						asideMobile={<AsideMobile />}
						routerLocation={location}
						asideDefault={<AsideDefault />}
						routerNameMap={routerNameMap}
						routerLinkMap={routerLinkMap}
					/>
				}
			>
				{/* Welcome Page */}
				<Route path={PATH_HOME} element={<WelcomePage appName={Translation('MENU.NETWORK.TITLE')} />} />
				{/* Default route path */}
				<Route path={'*'} element={<Navigate to={PATH_HOME} replace />} />

				{/* To be removed when permissions fixed */}
				<Route path={PATH_CABLES_LIST_MODEL} element={<ListCable />} />
				<Route path={PATH_CABLES_READ_MODEL} element={<ReadCable />} />

				{/* CABLES PAGES */}
				{/* Cable read page */}
				<Route
					// the path you can add a model to show at the breadcrumb
					path={PATH_CABLES_READ_MODEL}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_CABLES)!}>
							<ReadCable />
						</AuthRoute>
					}
				/>

				{/* Cable List page */}
				<Route
					// the path you can add a model to show at the breadcrumb
					path={PATH_CABLES_LIST_MODEL}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_CABLES)!}>
							<ListCable />
						</AuthRoute>
					}
				/>
			</Route>
		</Routes>
	)
}

export { PrivateRoutes }
