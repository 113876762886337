import { useRef, useContext } from 'react'
import { AuthContext } from 'ufinet-web-functions'
import { UfinetBox, UfinetModal } from 'ufinet-web-components'
import { useQuery } from '@tanstack/react-query'
import { getRow } from 'api/requests'
import { useLocation } from 'react-router-dom'
import { Loading } from 'ufinet-web-components'
import EditModal from 'components/modals/EditModal'
import { useModal } from 'ufinet-web-functions'
import { Translation } from 'utils/translation/Translation'
import { Authority } from 'ufinet-web-functions'

const ReadCable = () => {
	const location = useLocation()
	const id = new URLSearchParams(location.search).get('id')
	const spinnerRef = useRef(null)

	const authData = useContext(AuthContext)
	const roles = authData.userData?.authorities || []
	const permissions: any = Authority.getCablePermissions(roles)

	const [editModal, showEditModal, hideEditModal] = useModal()

	const query = useQuery({
		queryKey: ['getSingleCable'],
		queryFn: () => (id ? getRow(id) : undefined),
		enabled: !!id,
	})
	const { data, isPending, isError, error } = query

	const NewUfinetBox = UfinetBox as any

	if (id === null) {
		return <p>{Translation('CABLE.ONLYQRACCESIBLE')}</p>
	}

	return (
		<>
			{isPending ? (
				<Loading ref={spinnerRef} userClasses="spinner-grow-sm" />
			) : isError ? (
				<>
					<p>{Translation('CABLE.CABLEAPIFAILED')}</p>
					<p>{error.message}</p>
				</>
			) : (
				<NewUfinetBox>
					<p>
						<strong>{Translation('EDITMODAL.ID')}:</strong> {data?.data.id}
					</p>
					<p>
						<strong>{Translation('EDITMODAL.NAME')}:</strong> {data?.data.name}{' '}
						{permissions.canUpdate && <i className="pi pi-pencil edit-pencil" onClick={showEditModal} />}
					</p>
				</NewUfinetBox>
			)}
			<UfinetModal
				size="lg"
				show={editModal}
				handleClose={() => {
					hideEditModal()
				}}
				title={Translation('EDITMODAL.TITLE')}
			>
				<EditModal
					hideModal={hideEditModal}
					row={data?.data}
					successFn={() => {
						query.refetch()
					}}
				/>
			</UfinetModal>
		</>
	)
}

export default ReadCable
