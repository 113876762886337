import { FC, useEffect } from 'react'
import { UfinetActionButton, UfinetInput } from 'ufinet-web-components'
import { onFormikTextChanges } from 'ufinet-web-functions'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { editRow } from 'api/requests'
import { Translation } from 'utils/translation/Translation'

type NewAndEditCtoModalProps = {
	hideModal: Function
	row?: any
	successFn: Function
}

const EditModal: FC<NewAndEditCtoModalProps> = ({ hideModal, row, successFn }) => {
	const queryClient = useQueryClient()

	const mutation = useMutation({
		mutationFn: (values: any) => editRow(values),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['cables-list'] })
			hideModal()
			successFn && successFn()
			toast.success('mensaje de exito')
		},
		onError: (error: any) => {
			toast.error(error?.response?.data?.message || error?.response?.data?.detail || error?.response?.data?.title)
		},
	})

	useEffect(() => {
		return () => {
			formik.resetForm()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { mutateAsync: mutate, isPending } = mutation

	const formik = useFormik({
		initialValues: { id: row.id, name: row.name },
		validationSchema: Yup.object().shape({
			id: Yup.string().required('Required'),
			name: Yup.string().required('Required'),
		}),
		onSubmit: (values) => mutate({ ...values, globalId: row.globalId }),
		validateOnChange: false,
		validateOnBlur: false,
	})

	type MyFormikErrors = {
		[key: string]: string
	}

	return (
		<form onSubmit={formik.handleSubmit} className="container p-15 h-100 d-flex flex-column justify-content-center">
			<div className="row pb-10">
				<UfinetInput
					key="id"
					// requiredIcon={true}
					error={(formik.errors as MyFormikErrors)['id']}
					type="text"
					labelTitle={Translation('EDITMODAL.ID')}
					className="col-12"
					isDisabled={true}
					onChange={(e: any) => {
						formik.setFieldValue('id', e.target.value)
					}}
					value={row.id}
				/>
				<UfinetInput
					key="name"
					requiredIcon={true}
					error={(formik.errors as MyFormikErrors)['name']}
					type="text"
					labelTitle={Translation('EDITMODAL.NAME')}
					className="col-12"
					onChange={onFormikTextChanges(formik, 'name')}
					value={formik.values.name}
				/>
			</div>
			<UfinetActionButton isDisabled={isPending} className="mt-10" content={Translation('EDITMODAL.SAVE')} />
		</form>
	)
}

export default EditModal
