import { fetchGET2, fetchPUT2 } from 'api/https/https'

interface Params {
	[key: string]: string
}

const normalizeParams = (params: Params) => {
	const serchParms = new URLSearchParams()

	//If there's no pageNumber and rowsPerPage, append them
	if (!params.pageNumber && !params.rowsPerPage) {
		serchParms.append('pageNumber', '0')
		serchParms.append('rowsPerPage', '20')
	}

	for (const [key, value] of Object.entries(params)) {
		if (value !== null) serchParms.append(key, value)
	}

	return serchParms.toString()
}

// Get single row
const getRow = async (id: string) => {
	return await fetchGET2(`Cable/GetCable/${id}`)
}

// Get all rows
const getRows = async (filterData: any) => {
	filterData.country = 'Argentina'
	return await fetchGET2(`Cable/ListCables?${normalizeParams(filterData)}`)
}

// Update a row
const editRow = async (data: any) => {
	// @ts-ignore
	return await fetchPUT2(`Cable/UpdateCableQr`, [data])
}

export { getRow, getRows, editRow }
